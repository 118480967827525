'use strict';

var constants = {
  '3000': {
    propane: {
      cost: 19400,
      fuelConsumption: 1.37612,
      coHour: 2.25480671408352,
      co2Hour: 12.70
    },
    diesel: {
      cost: 20200,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 28000,
      fuelConsumption: 6.23397,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '4000': {
    propane: {
      cost: 22700,
      fuelConsumption: 1.37612,
      coHour: 2.25352570624059,
      co2Hour: 12.70
    },
    diesel: {
      cost: 23800,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 32200,
      fuelConsumption: 7.53205,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '5000': {
    propane: {
      cost: 24200,
      fuelConsumption: 1.37612,
      coHour: 2.25352570624059,
      co2Hour: 12.70
    },
    diesel: {
      cost: 25100,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 34400,
      fuelConsumption: 7.53205,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '6000': {
    propane: {
      cost: 29100,
      fuelConsumption: 1.75224,
      coHour: 2.25425740766105,
      co2Hour: 12.70
    },
    diesel: {
      cost: 31300,
      fuelConsumption: 1.07436,
      coHour: 3.77087945500768,
      co2Hour: 22.40
    },
    electric: {
      cost: 39900,
      fuelConsumption: 8.70192,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '7000': {
    propane: {
      cost: 34800,
      fuelConsumption: 1.75224,
      coHour: 2.25425740766105,
      co2Hour: 12.70
    },
    diesel: {
      cost: 34500,
      fuelConsumption: 1.17356,
      coHour: 3.77180741307842,
      co2Hour: 22.40
    },
    electric: {
      cost: 47700,
      fuelConsumption: 8.70192,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '8000': {
    propane: {
      cost: 36000,
      fuelConsumption: 1.75224,
      coHour: 2.25315991135742,
      co2Hour: 12.70
    },
    diesel: {
      cost: 35700,
      fuelConsumption: 1.17356,
      coHour: 3.77016874362558,
      co2Hour: 22.40
    },
    electric: {
      cost: 50100,
      fuelConsumption: 10,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '10000': {
    propane: {
      cost: 41800,
      fuelConsumption: 2.06426,
      coHour: 2.25339955436939,
      co2Hour: 12.70
    },
    diesel: {
      cost: 42500,
      fuelConsumption: 1.44626,
      coHour: 3.77122140325161,
      co2Hour: 22.40
    },
    electric: {
      cost: 52800,
      fuelConsumption: 11.29808,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '12500': {
    propane: {
      cost: 47700,
      fuelConsumption: 2.06426,
      coHour: 2.25262321606774,
      co2Hour: 12.70
    },
    diesel: {
      cost: 46000,
      fuelConsumption: 1.44626,
      coHour: 3.77011332856063,
      co2Hour: 22.40
    },
    electric: {
      cost: 59900,
      fuelConsumption: 12.46794,
      coHour: 0,
      co2Hour: 1.009
    }
  }
};

function Calculation(data) {
  var _this = this;

  this.inputs = data.inputs;
  this.results = {
    electric: {},
    propane: {},
    diesel: {},
    electricDiesel: {},
    electricPropane: {},
    inputs: data.inputs
  };
  this.hours = this.inputs.hours_day * this.inputs.days_week * this.inputs.weeks_year * this.inputs.years;

  this.capitalCost = function () {
    _this.results.electric.cost = _this.inputs.electric_capital * 1;
    _this.results.propane.cost = _this.inputs.propane_capital * 1;
    _this.results.diesel.cost = _this.inputs.diesel_capital * 1;
  };
  this.omCost = function () {
    _this.results.electric.maintenance = _this.hours * _this.inputs.electric_maint;
    _this.results.propane.maintenance = _this.hours * _this.inputs.propane_maint;
    _this.results.diesel.maintenance = _this.hours * _this.inputs.diesel_maint;
  };
  this.fuelCost = function () {
    _this.results.electric.fuelCost = _this.hours * constants[_this.inputs.capacity].electric.fuelConsumption * _this.inputs.electric_price;
    _this.results.propane.fuelCost = _this.hours * constants[_this.inputs.capacity].propane.fuelConsumption * _this.inputs.propane_price;
    _this.results.diesel.fuelCost = _this.hours * constants[_this.inputs.capacity].diesel.fuelConsumption * _this.inputs.diesel_price;
  };
  this.totalCost = function () {
    _this.results.electric.totalCost = _this.results.electric.cost + _this.results.electric.maintenance + _this.results.electric.fuelCost;
    _this.results.propane.totalCost = _this.results.propane.cost + _this.results.propane.maintenance + _this.results.propane.fuelCost;
    _this.results.diesel.totalCost = _this.results.diesel.cost + _this.results.diesel.maintenance + _this.results.diesel.fuelCost;
  };

  this.electricDieselSavings = function () {
    _this.results.electricDiesel.savings = _this.results.electric.totalCost - _this.results.diesel.totalCost;

    _this.results.electricDiesel.co = _this.hours * constants[_this.inputs.capacity].diesel.fuelConsumption * constants[_this.inputs.capacity].diesel.coHour;

    _this.results.electricDiesel.co2 = _this.hours * constants[_this.inputs.capacity].diesel.fuelConsumption * constants[_this.inputs.capacity].diesel.co2Hour - _this.hours * constants[_this.inputs.capacity].electric.fuelConsumption * constants[_this.inputs.capacity].electric.co2Hour;
  };
  this.electricPropaneSavings = function () {
    _this.results.electricPropane.savings = _this.results.electric.totalCost - _this.results.propane.totalCost;

    _this.results.electricPropane.co = _this.hours * constants[_this.inputs.capacity].propane.fuelConsumption * constants[_this.inputs.capacity].propane.coHour;

    _this.results.electricPropane.co2 = _this.hours * constants[_this.inputs.capacity].propane.fuelConsumption * constants[_this.inputs.capacity].propane.co2Hour - _this.hours * constants[_this.inputs.capacity].electric.fuelConsumption * constants[_this.inputs.capacity].electric.co2Hour;
  };

  this.savings = function () {
    _this.capitalCost();
    _this.omCost();
    _this.fuelCost();
    _this.totalCost();
    _this.electricDieselSavings();
    _this.electricPropaneSavings();
    return _this.results;
  };
}
(function ($) {

  var chart = void 0;

  $(document).ready(function () {

    var $container = $('.global-container'),
        $results = $('.results-wrapper'),
        $inputs = $(':input'),
        $form = $('#calculation-form'),
        $resetButton = $('button[type="reset"]');

    initInputs($inputs);
    initForm($form, $container);
    initResults($results, $container, $inputs);

    $('select[name="capacity"]').on('change', function () {

      var $el = $(this),
          cap = $el.val();

      if ($el.validate()) {
        $('.input-capital').each(function () {

          var $inp = $(this).find('input');
          $(this).attr('data-visible', true);

          if (!$(this).hasClass('text')) {
            $inp.val(constants[cap][$inp.attr('data-prefill')].cost).setValid().setHasInput();
          }
        });
      } else {
        $('.input-capital').each(function () {
          $(this).attr('data-visible', false);
        });
      }
    });

    $resetButton.click(function (e) {
      $inputs.each(function () {
        $(this).initialize();
      });

      $('.input-capital').each(function () {
        $(this).attr('data-visible', false);
      });
    });
  });

  jQuery.fn.extend({
    checkEmpty: _checkEmpty,
    validate: _validate,
    initialize: _setInitial,
    setValid: _setValid,
    setInvalid: _setInvalid,
    setFocused: _setFocused,
    setBlurred: _setBlurred,
    setHasInput: _setHasInput,
    unsetHasInput: _unsetHasInput,
    setError: _setErrorMessage
  });

  function initInputs($inputs) {

    $inputs.each(function () {

      var $el = $(this),
          $wrapper = $(this).parents('.input-wrap');

      if ($el.attr('data-icon')) {
        $wrapper.append('\n          <div class="icon">\n            <img src="/sites/all/modules/aeped_calculator/img/usd.svg"/>\n          </div>\n        ');
        $wrapper.addClass('has-icon');
      }
    });
    $inputs.on('focus', function (event) {
      $(this).parents('.input-wrap').addClass('focused');
    });
    $inputs.on('blur', function (event) {

      var $el = $(this),
          val = $el.val(),
          $wrapper = $el.parents('.input-wrap');

      $wrapper.removeClass('focused');

      if (val === '' || val === 0) {
        $wrapper.removeClass('has-input');

        if (!$el.attr('required')) {
          $wrapper.removeClass('invalid');
        }
      }
    });
    $inputs.on('input', _validate);
    $inputs.on('change', _validate);
  }

  function initForm($form, $container) {

    var $submitButton = $form.find('button[type="submit"]');

    $submitButton.click(function (e) {

      e.preventDefault();

      $form.find('.messages').empty();
      $('.input-container').removeClass('has-messages');

      var valid = true;

      var fields = {},
          $inputs = $form.find('input, select');

      $inputs.each(function () {
        var $inp = $(this);
        if (!$inp.validate()) {
          valid = false;
          return;
        }
        fields[$inp.attr('name')] = $inp.val();
      });

      if (valid) {

        var calc = new Calculation({
          inputs: fields
        });

        setResults(calc.savings());

        $container.addClass('results-visible');
      }
    });
  }

  function initResults($results, $container, $inputs) {

    var $closeButton = $results.find('[data-close-results]'),
        $recalculate = $results.find('[data-recalculate]');

    $closeButton.click(function (e) {
      e.preventDefault();

      $container.removeClass('results-visible');
    });

    $recalculate.click(function (e) {
      e.preventDefault();

      $container.removeClass('results-visible');
      $inputs.each(function () {
        $(this).initialize();
      });
    });
  }

  function _validate() {

    var $el = $(this),
        $wrapper = $el.parents('.input-wrap'),
        $inputContainer = $wrapper.parents('.input-container'),
        $messages = $inputContainer.find('.messages'),
        val = $el.val() * 1,
        name = $el.attr('name');

    if ($el.checkEmpty()) {
      $el.setInvalid();
      $el.setError(name, $wrapper.find('label').text() + ' is required');
      return false;
    } else {
      $messages.find('.message[data-error="' + name + '"]').remove();
    }

    $el.setHasInput();

    if ($el.attr('type') === 'number') {

      if (isNaN(val)) {
        $el.setInvalid();
        $el.setError(name, $wrapper.find('label').text() + ' must be a number');
        return false;
      } else {
        $messages.find('.message[data-error="' + name + '"]').remove();
      }

      var min = $el.attr('min') * 1,
          max = $el.attr('max') * 1;

      if (min && val < min) {
        $el.setInvalid();
        $el.setError(name, $wrapper.find('label').text() + ' must be at least ' + $el.attr('min'));
        return false;
      } else {
        $messages.find('.message[data-error="' + name + '"]').remove();
      }

      if (max && val > max) {
        $el.setInvalid();
        $el.setError(name, $wrapper.find('label').text() + ' must be fewer than ' + $el.attr('max'));
        return false;
      } else {
        $messages.find('.message[data-error="' + name + '"]').remove();
      }
    }

    $el.setValid();

    if ($messages.find('.message').length < 1) {
      $inputContainer.removeClass('has-messages');
    }

    return true;
  }

  function _checkEmpty() {
    var $input = $(this),
        val = $input.val(),
        $el = $input.parents('.input-wrap');

    if (val === '' || /^\s*$/.test(val) || val === null) {
      if ($input.attr('required')) {
        $input.setInvalid().unsetHasInput();
        return true;
      } else {
        $input.initialize();
        return false;
      }
    }
    return false;
  }

  function _setInitial() {

    var $parent = this.parents('.input-wrap');

    if ($parent.hasClass('has-default-input')) {
      $parent.removeClass('valid invalid focused');
    } else {
      $parent.removeClass('valid invalid has-input focused');
      this.val('');
    }

    return this;
  }

  function _setValid() {
    this.parents('.input-wrap').removeClass('invalid').addClass('valid');
    return this;
  }

  function _setInvalid() {
    this.parents('.input-wrap').removeClass('valid').addClass('invalid');
    return this;
  }

  function _setFocused() {
    this.parents('.input-wrap').addClass('focused');
    return this;
  }

  function _setBlurred() {
    this.parents('.input-wrap').removeClass('focused');
    return this;
  }

  function _setHasInput() {
    this.parents('.input-wrap').addClass('has-input');
    return this;
  }

  function _unsetHasInput() {
    this.parents('.input-wrap').removeClass('has-input');
    return this;
  }

  function _setErrorMessage(error, message) {

    var $el = $(this),
        $wrapper = $el.parents('.input-wrap'),
        $inputContainer = $wrapper.parents('.input-container'),
        $messages = $inputContainer.find('.messages');

    if ($messages.find('.message[data-error="' + error + '"]').length < 1) {
      $inputContainer.addClass('has-messages');
      $messages.append(getMessageTemplate(error, message));
    }
  }

  function getMessageTemplate(error, text) {
    return '\n      <div class="message" data-error="' + error + '">\n        ' + text + '\n      </div>\n    ';
  }

  function setResults(results) {

    var $results = $('.results-section');

    $('td.result').empty();

    $results.find('td.diesel.init').empty().append('$' + formatNumber(results.diesel.cost));
    $results.find('td.diesel.toc').empty().append('$' + formatNumber(results.diesel.totalCost));
    $results.find('td.diesel.om').empty().append('$' + formatNumber(results.diesel.maintenance));
    $results.find('td.diesel.fuel').empty().append('$' + formatNumber(results.diesel.fuelCost));

    $results.find('td.propane.init').empty().append('$' + formatNumber(results.propane.cost));
    $results.find('td.propane.toc').empty().append('$' + formatNumber(results.propane.totalCost));
    $results.find('td.propane.om').empty().append('$' + formatNumber(results.propane.maintenance));
    $results.find('td.propane.fuel').empty().append('$' + formatNumber(results.propane.fuelCost));

    $results.find('td.electric.init').empty().append('$' + formatNumber(results.electric.cost));
    $results.find('td.electric.toc').empty().append('$' + formatNumber(results.electric.totalCost));
    $results.find('td.electric.om').empty().append('$' + formatNumber(results.electric.maintenance));
    $results.find('td.electric.fuel').empty().append('$' + formatNumber(results.electric.fuelCost));

    if (-results.electricDiesel.savings < 0) $results.find('.savings.electric-diesel .capital').empty().append('<span class="negative">$' + formatNumber(-results.electricDiesel.savings) + '</span>');else $results.find('.savings.electric-diesel .capital').empty().append('$' + formatNumber(-results.electricDiesel.savings));

    $results.find('.savings.electric-diesel .co').empty().append(formatNumber(results.electricDiesel.co) + ' lb/year');
    $results.find('.savings.electric-diesel .co2').empty().append(formatNumber(results.electricDiesel.co2) + ' lb/year');

    if (-results.electricPropane.savings < 0) $results.find('.savings.electric-propane .capital').empty().append('<span class="negative">$' + formatNumber(-results.electricPropane.savings) + '</span>');else $results.find('.savings.electric-propane .capital').empty().append('$' + formatNumber(-results.electricPropane.savings));

    $results.find('.savings.electric-propane .co').empty().append(formatNumber(results.electricPropane.co) + ' lb/year');
    $results.find('.savings.electric-propane .co2').empty().append(formatNumber(results.electricPropane.co2) + ' lb/year');

    if (chart && chart.hasOwnProperty('destroy')) {
      chart.destroy();
    }

    var chartEl = document.getElementById('results-chart').getContext('2d');
    chartEl.height = 300;
    chart = new Chart(chartEl, {
      type: 'bar',
      data: {
        labels: ['Electric', 'Diesel', 'Propane'],
        datasets: [{
          label: 'Capital',
          backgroundColor: '#84BE44',
          data: [Math.round(results.electric.cost), Math.round(results.diesel.cost), Math.round(results.propane.cost)]
        }, {
          label: 'O&M',
          backgroundColor: '#707372',
          data: [Math.round(results.electric.maintenance), Math.round(results.diesel.maintenance), Math.round(results.propane.maintenance)]
        }, {
          label: 'Fuel Cost',
          backgroundColor: '#3399FF',
          data: [Math.round(results.electric.fuelCost), Math.round(results.diesel.fuelCost), Math.round(results.propane.fuelCost)]
        }]
      },
      options: {
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            // Include a dollar sign and formatting in tooltip label
            label: function label(tooltipItem, data) {
              return '$' + formatNumber(tooltipItem.yLabel);
            }
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'USD'
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function callback(value, index, values) {
                return '$' + formatNumber(value);
              }
            }
          }]
        },
        maintainAspectRatio: false
      }
    });
  }

  var formatNumber = function formatNumber(num) {

    var number = num;

    if (num < 0) {
      number = -num;
    }

    var parts = Math.round(number).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num < 0 ? '(' + parts.join(".") + ')' : parts.join(".");
  };
})(jQuery);