﻿(function ($) {

  let chart;

  $(document).ready(function(){

    const $container = $('.global-container'),
      $results = $('.results-wrapper'),
      $inputs = $(':input'),
      $form = $('#calculation-form'),
      $resetButton = $('button[type="reset"]');

    initInputs($inputs);
    initForm($form, $container);
    initResults($results, $container, $inputs);

    $('select[name="capacity"]').on('change', function(){

      const $el = $(this),
        cap = $el.val();

      if($el.validate()){
        $('.input-capital').each(function(){

          const $inp = $(this).find('input');
          $(this).attr('data-visible', true);

          if(!$(this).hasClass('text')) {
            $inp.val(constants[cap][$inp.attr('data-prefill')].cost).setValid().setHasInput();
          }

        });
      }else{
        $('.input-capital').each(function(){
          $(this).attr('data-visible', false);
        });
      }

    });

    $resetButton.click(function(e){
      $inputs.each(function(){
        $(this).initialize();
      });

      $('.input-capital').each(function(){
        $(this).attr('data-visible', false);
      });
    });

  });

  jQuery.fn.extend({
    checkEmpty: _checkEmpty,
    validate: _validate,
    initialize: _setInitial,
    setValid: _setValid,
    setInvalid: _setInvalid,
    setFocused: _setFocused,
    setBlurred: _setBlurred,
    setHasInput: _setHasInput,
    unsetHasInput: _unsetHasInput,
    setError: _setErrorMessage
  });

  function initInputs ($inputs) {

    $inputs.each(function(){

      const $el = $(this),
        $wrapper = $(this).parents('.input-wrap');

      if($el.attr('data-icon')){
        $wrapper.append(`
          <div class="icon">
            <img src="/sites/all/modules/aeped_calculator/img/usd.svg"/>
          </div>
        `);
        $wrapper.addClass('has-icon');
      }

    });
    $inputs.on('focus', function(event){
      $(this).parents('.input-wrap').addClass('focused');
    });
    $inputs.on('blur', function(event){

      const $el = $(this),
        val = $el.val(),
        $wrapper = $el.parents('.input-wrap');

      $wrapper.removeClass('focused');

      if(val === '' || val === 0){
        $wrapper.removeClass('has-input');

        if(!$el.attr('required')){
          $wrapper.removeClass('invalid');
        }
      }

    });
    $inputs.on('input', _validate);
    $inputs.on('change', _validate);

  }

  function initForm ($form, $container) {

    const $submitButton = $form.find('button[type="submit"]');

    $submitButton.click(function(e){

      e.preventDefault();

      $form.find('.messages').empty();
      $('.input-container').removeClass('has-messages');

      let valid = true;

      const fields = {},
        $inputs = $form.find('input, select');

      $inputs.each(function(){
        const $inp = $(this);
        if(!$inp.validate()){
          valid = false;
          return;
        }
        fields[$inp.attr('name')] = $inp.val();
      });

      if(valid){

        const calc = new Calculation({
          inputs: fields
        });

        setResults(calc.savings());

        $container.addClass('results-visible');
      }

    });

  }

  function initResults ($results, $container, $inputs) {

    const $closeButton = $results.find('[data-close-results]'),
      $recalculate = $results.find('[data-recalculate]');

    $closeButton.click(function(e){
      e.preventDefault();

      $container.removeClass('results-visible');
    });

    $recalculate.click(function(e){
      e.preventDefault();

      $container.removeClass('results-visible');
      $inputs.each(function(){
        $(this).initialize();
      });
    });

  }

  function _validate () {

    const $el = $(this),
      $wrapper = $el.parents('.input-wrap'),
      $inputContainer = $wrapper.parents('.input-container'),
      $messages = $inputContainer.find('.messages'),
      val = $el.val() * 1,
      name = $el.attr('name');

    if($el.checkEmpty()){
      $el.setInvalid();
      $el.setError(name, `${$wrapper.find('label').text()} is required`);
      return false;
    }else{
      $messages.find(`.message[data-error="${name}"]`).remove();
    }

    $el.setHasInput();

    if($el.attr('type') === 'number'){

      if(isNaN(val)){
        $el.setInvalid();
        $el.setError(name, `${$wrapper.find('label').text()} must be a number`);
        return false;
      }else{
        $messages.find(`.message[data-error="${name}"]`).remove();
      }

      const min = $el.attr('min') * 1,
        max = $el.attr('max') * 1;

      if(min && val < min){
        $el.setInvalid();
        $el.setError(name, `${$wrapper.find('label').text()} must be at least ${$el.attr('min')}`);
        return false;
      }else{
        $messages.find(`.message[data-error="${name}"]`).remove();
      }

      if(max && val > max){
        $el.setInvalid();
        $el.setError(name, `${$wrapper.find('label').text()} must be fewer than ${$el.attr('max')}`);
        return false;
      }else{
        $messages.find(`.message[data-error="${name}"]`).remove();
      }

    }

    $el.setValid();

    if($messages.find('.message').length < 1) {
      $inputContainer.removeClass('has-messages');
    }

    return true;

  }

  function _checkEmpty () {
    const $input = $(this),
      val = $input.val(),
      $el = $input.parents('.input-wrap');

    if(val === '' || /^\s*$/.test(val) || val === null){
      if($input.attr('required')){
        $input.setInvalid().unsetHasInput();
        return true;
      }else{
        $input.initialize();
        return false;
      }
    }
    return false;
  }

  function _setInitial () {

    let $parent = this.parents('.input-wrap');

    if($parent.hasClass('has-default-input')){
      $parent.removeClass('valid invalid focused');
    }else{
      $parent.removeClass('valid invalid has-input focused');
      this.val('');
    }

    return this;

  }

  function _setValid () {
    this.parents('.input-wrap').removeClass('invalid').addClass('valid');
    return this;
  }

  function _setInvalid () {
    this.parents('.input-wrap').removeClass('valid').addClass('invalid');
    return this;
  }

  function _setFocused () {
    this.parents('.input-wrap').addClass('focused');
    return this;
  }

  function _setBlurred () {
    this.parents('.input-wrap').removeClass('focused');
    return this;
  }

  function _setHasInput () {
    this.parents('.input-wrap').addClass('has-input');
    return this;
  }

  function _unsetHasInput () {
    this.parents('.input-wrap').removeClass('has-input');
    return this;
  }

  function _setErrorMessage (error, message) {

    const $el = $(this),
      $wrapper = $el.parents('.input-wrap'),
      $inputContainer = $wrapper.parents('.input-container'),
      $messages = $inputContainer.find('.messages');

    if($messages.find(`.message[data-error="${error}"]`).length < 1){
      $inputContainer.addClass('has-messages');
      $messages.append(getMessageTemplate(error, message));
    }

  }

  function getMessageTemplate (error, text) {
    return `
      <div class="message" data-error="${error}">
        ${text}
      </div>
    `;
  }

  function setResults (results) {

    const $results = $('.results-section');

    $('td.result').empty();

    $results.find('td.diesel.init').empty().append(`$${formatNumber(results.diesel.cost)}`);
    $results.find('td.diesel.toc').empty().append(`$${formatNumber(results.diesel.totalCost)}`);
    $results.find('td.diesel.om').empty().append(`$${formatNumber(results.diesel.maintenance)}`);
    $results.find('td.diesel.fuel').empty().append(`$${formatNumber(results.diesel.fuelCost)}`);

    $results.find('td.propane.init').empty().append(`$${formatNumber(results.propane.cost)}`);
    $results.find('td.propane.toc').empty().append(`$${formatNumber(results.propane.totalCost)}`);
    $results.find('td.propane.om').empty().append(`$${formatNumber(results.propane.maintenance)}`);
    $results.find('td.propane.fuel').empty().append(`$${formatNumber(results.propane.fuelCost)}`);

    $results.find('td.electric.init').empty().append(`$${formatNumber(results.electric.cost)}`);
    $results.find('td.electric.toc').empty().append(`$${formatNumber(results.electric.totalCost)}`);
    $results.find('td.electric.om').empty().append(`$${formatNumber(results.electric.maintenance)}`);
    $results.find('td.electric.fuel').empty().append(`$${formatNumber(results.electric.fuelCost)}`);

    if(-results.electricDiesel.savings < 0)
      $results.find('.savings.electric-diesel .capital').empty().append(`<span class="negative">$${formatNumber(-results.electricDiesel.savings)}</span>`);
    else
      $results.find('.savings.electric-diesel .capital').empty().append(`$${formatNumber(-results.electricDiesel.savings)}`);

    $results.find('.savings.electric-diesel .co').empty().append(`${formatNumber(results.electricDiesel.co)} lb/year`);
    $results.find('.savings.electric-diesel .co2').empty().append(`${formatNumber(results.electricDiesel.co2)} lb/year`);

    if(-results.electricPropane.savings < 0)
      $results.find('.savings.electric-propane .capital').empty().append(`<span class="negative">$${formatNumber(-results.electricPropane.savings)}</span>`);
    else
      $results.find('.savings.electric-propane .capital').empty().append(`$${formatNumber(-results.electricPropane.savings)}`);

    $results.find('.savings.electric-propane .co').empty().append(`${formatNumber(results.electricPropane.co)} lb/year`);
    $results.find('.savings.electric-propane .co2').empty().append(`${formatNumber(results.electricPropane.co2)} lb/year`);

    if(chart && chart.hasOwnProperty('destroy')){
      chart.destroy();
    }

    let chartEl = document.getElementById('results-chart').getContext('2d');
    chartEl.height = 300;
    chart = new Chart(
      chartEl,
      {
        type: 'bar',
        data: {
          labels: ['Electric', 'Diesel', 'Propane'],
          datasets: [
            {
              label: 'Capital',
              backgroundColor: '#84BE44',
              data: [
                Math.round(results.electric.cost),
                Math.round(results.diesel.cost),
                Math.round(results.propane.cost),
              ]
            },
            {
              label: 'O&M',
              backgroundColor: '#707372',
              data: [
                Math.round(results.electric.maintenance),
                Math.round(results.diesel.maintenance),
                Math.round(results.propane.maintenance)
              ]
            },
            {
              label: 'Fuel Cost',
              backgroundColor: '#3399FF',
              data: [
                Math.round(results.electric.fuelCost),
                Math.round(results.diesel.fuelCost),
                Math.round(results.propane.fuelCost)
              ]
            }
          ]
        },
        options: {
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              // Include a dollar sign and formatting in tooltip label
              label: function(tooltipItem, data) {
                return `$${formatNumber(tooltipItem.yLabel)}`;
              }
            }
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'USD'
              },
              ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  return `$${formatNumber(value)}`;
                }
              }
            }]
          },
          maintainAspectRatio: false,
        }
      }
    );

  }

  const formatNumber = (num) => {

    let number = num;

    if(num < 0){
      number = -num;
    }

    let parts = Math.round(number).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num < 0 ? `(${parts.join(".")})` : parts.join(".");

  };

})(jQuery);
