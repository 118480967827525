﻿const constants = {
  '3000': {
    propane: {
      cost: 19400,
      fuelConsumption: 1.37612,
      coHour: 2.25480671408352,
      co2Hour: 12.70
    },
    diesel: {
      cost: 20200,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 28000,
      fuelConsumption: 6.23397,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '4000': {
    propane: {
      cost: 22700,
      fuelConsumption: 1.37612,
      coHour: 2.25352570624059,
      co2Hour: 12.70
    },
    diesel: {
      cost: 23800,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 32200,
      fuelConsumption: 7.53205,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '5000': {
    propane: {
      cost: 24200,
      fuelConsumption: 1.37612,
      coHour: 2.25352570624059,
      co2Hour: 12.70
    },
    diesel: {
      cost: 25100,
      fuelConsumption: 0.9008,
      coHour: 3.77264368993943,
      co2Hour: 22.40
    },
    electric: {
      cost: 34400,
      fuelConsumption: 7.53205,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '6000': {
    propane: {
      cost: 29100,
      fuelConsumption: 1.75224,
      coHour: 2.25425740766105,
      co2Hour: 12.70
    },
    diesel: {
      cost: 31300,
      fuelConsumption: 1.07436,
      coHour: 3.77087945500768,
      co2Hour: 22.40
    },
    electric: {
      cost: 39900,
      fuelConsumption: 8.70192,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '7000': {
    propane: {
      cost: 34800,
      fuelConsumption: 1.75224,
      coHour: 2.25425740766105,
      co2Hour: 12.70
    },
    diesel: {
      cost: 34500,
      fuelConsumption: 1.17356,
      coHour: 3.77180741307842,
      co2Hour: 22.40
    },
    electric: {
      cost: 47700,
      fuelConsumption: 8.70192,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '8000': {
    propane: {
      cost: 36000,
      fuelConsumption: 1.75224,
      coHour: 2.25315991135742,
      co2Hour: 12.70
    },
    diesel: {
      cost: 35700,
      fuelConsumption: 1.17356,
      coHour: 3.77016874362558,
      co2Hour: 22.40
    },
    electric: {
      cost: 50100,
      fuelConsumption: 10,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '10000': {
    propane: {
      cost: 41800,
      fuelConsumption: 2.06426,
      coHour: 2.25339955436939,
      co2Hour: 12.70
    },
    diesel: {
      cost: 42500,
      fuelConsumption: 1.44626,
      coHour: 3.77122140325161,
      co2Hour: 22.40
    },
    electric: {
      cost: 52800,
      fuelConsumption: 11.29808,
      coHour: 0,
      co2Hour: 1.009
    }
  },
  '12500': {
    propane: {
      cost: 47700,
      fuelConsumption: 2.06426,
      coHour: 2.25262321606774,
      co2Hour: 12.70
    },
    diesel: {
      cost: 46000,
      fuelConsumption: 1.44626,
      coHour: 3.77011332856063,
      co2Hour: 22.40
    },
    electric: {
      cost: 59900,
      fuelConsumption: 12.46794,
      coHour: 0,
      co2Hour: 1.009
    }
  }
};

function Calculation (data) {

  this.inputs = data.inputs;
  this.results = {
    electric: {},
    propane: {},
    diesel: {},
    electricDiesel: {},
    electricPropane: {},
    inputs: data.inputs
  };
  this.hours = this.inputs.hours_day * this.inputs.days_week * this.inputs.weeks_year * this.inputs.years;

  this.capitalCost = () => {
    this.results.electric.cost = this.inputs.electric_capital*1;
    this.results.propane.cost = this.inputs.propane_capital*1;
    this.results.diesel.cost = this.inputs.diesel_capital*1;
  };
  this.omCost = () => {
    this.results.electric.maintenance = this.hours * this.inputs.electric_maint;
    this.results.propane.maintenance = this.hours * this.inputs.propane_maint;
    this.results.diesel.maintenance =  this.hours * this.inputs.diesel_maint;
  };
  this.fuelCost = () => {
    this.results.electric.fuelCost = this.hours * constants[this.inputs.capacity].electric.fuelConsumption * this.inputs.electric_price;
    this.results.propane.fuelCost = this.hours * constants[this.inputs.capacity].propane.fuelConsumption * this.inputs.propane_price;
    this.results.diesel.fuelCost = this.hours * constants[this.inputs.capacity].diesel.fuelConsumption * this.inputs.diesel_price;
  };
  this.totalCost = () => {
    this.results.electric.totalCost = this.results.electric.cost + this.results.electric.maintenance + this.results.electric.fuelCost;
    this.results.propane.totalCost = this.results.propane.cost + this.results.propane.maintenance + this.results.propane.fuelCost;
    this.results.diesel.totalCost = this.results.diesel.cost + this.results.diesel.maintenance + this.results.diesel.fuelCost;
  };

  this.electricDieselSavings = () => {
    this.results.electricDiesel.savings = this.results.electric.totalCost - this.results.diesel.totalCost;

    this.results.electricDiesel.co = (
      this.hours *
      constants[this.inputs.capacity].diesel.fuelConsumption *
      constants[this.inputs.capacity].diesel.coHour
    );

    this.results.electricDiesel.co2 = (
        this.hours *
        constants[this.inputs.capacity].diesel.fuelConsumption *
        constants[this.inputs.capacity].diesel.co2Hour
      ) - (
        this.hours *
        constants[this.inputs.capacity].electric.fuelConsumption  *
        constants[this.inputs.capacity].electric.co2Hour
      );

  };
  this.electricPropaneSavings = () => {
    this.results.electricPropane.savings = this.results.electric.totalCost - this.results.propane.totalCost;

    this.results.electricPropane.co = (
      this.hours *
      constants[this.inputs.capacity].propane.fuelConsumption *
      constants[this.inputs.capacity].propane.coHour
    );

    this.results.electricPropane.co2 = (
        this.hours *
        constants[this.inputs.capacity].propane.fuelConsumption *
        constants[this.inputs.capacity].propane.co2Hour
      ) - (
        this.hours *
        constants[this.inputs.capacity].electric.fuelConsumption  *
        constants[this.inputs.capacity].electric.co2Hour
      );
  };

  this.savings = () => {
    this.capitalCost();
    this.omCost();
    this.fuelCost();
    this.totalCost();
    this.electricDieselSavings();
    this.electricPropaneSavings();
    return this.results;
  };

}